import React from "react";

class Footer extends React.Component {
  render() {
    return (
<footer id="footer">
              <div className="insideFooter">
                <ul className="nav" style={{
                                  marginLeft:"-40px"
                                }}>
                <li>
                  <a id="ot-sdk-btn" className="ot-sdk-show-settings" rel="nofollow">Cookie Policy</a>
                </li>
                  
                  <li style={{
                                  fontSize:"11.5px"
                                }}>
                    <a href="/gr/legal/contact-us/" target="_self">
                      Επικοινωνία
                    </a>
                  </li>
                  <li style={{
                                  fontSize:"11.5px"
                                }}>
                    <a href="/gr/legal/legal-notice/" target="_self">
                      Νομικό Σημείωμα
                    </a>
                  </li>
                  <li style={{
                                  fontSize:"11.5px"
                                }}>
                    <a href="/gr/legal/private-policy/" target="_self">
                      Πολιτική Απορρήτου
                    </a>
                  </li>
                  <li style={{
                                  fontSize:"11.5px"
                                }}>
                    <a href="/gr/legal/cookie-policy/" target="_self">
                      Πολιτική σχετικά με τα cookies
                    </a>
                  </li>
                </ul>
                <div className="clear" /><br />
                <p
                  style={{
                    float: "none"
                  }}
                >
                  Αυτή η ιστοσελίδα κατέχεται από την Johnson &amp; Johnson
                  Ελλάς
                  <br /> Καταναλωτικά Προϊόντα ΑΕΕ, η οποία είναι και η μοναδική
                  υπεύθυνη
                  <br /> για το περιεχόμενό της. Απευθύνεται σε επισκέπτες από
                  την Ελλάδα.
                  <br /> Με την πρόσβαση και την προσπέλαση της παρούσας
                  ιστοσελίδας
                  <br /> δηλώνετε την πλήρη συγκατάθεσή σας στο Νομικό μας
                  Σημείωμα
                  <br />
                  και στην Πολιτική Απορρήτου.
                </p>
                <p
                  style={{
                    float: "none"
                  }}
                >
                  © Johnson &amp; Johnson Ελλάς Καταναλωτικά Προϊοντα ΑΕΕ, 2017
                  <br />
                  Τελευταία ενημέρωση: 31/08/2021{" "}
                </p>
                <p> </p>
              </div>
            </footer>
    );
  }
}

export default Footer;
